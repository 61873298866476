<script lang="ts">
	import { _ } from 'svelte-i18n';
</script>

<svelte:head>
	<title>{$_('app.name')}</title>
</svelte:head>

<div class="flex flex-col items-center justify-center min-h-[calc(100vh-80px)] p-4">
	<h1 class="text-4xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 md:mb-8 text-gray-800 dark:text-black text-center">
		{$_('home.welcome')}
	</h1>
	<h3 class="text-2xl sm:text-3xl md:text-4xl font-semibold mb-4 sm:mb-6 md:mb-8 text-gray-700 dark:text-black text-center">
		{$_('home.info')}
	</h3>
</div>
